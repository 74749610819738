import gql from 'graphql-tag'

export const MUTATION_UPLOAD_IMAGE = gql`
  mutation UploadImageLandingPageFranchise($accountId: String!, $file: Upload!) {
    uploadImageLandingPageFranchise(accountId: $accountId, file: $file) {
      urlImage
    }
  }
`
export const MUTATION_UPLOAD_IMAGE_BASE_64 = gql`
  mutation UploadBase64LandingPageFranchise($accountId: String!, $filename: String!, $base64: String!) {
    uploadBase64LandingPageFranchise(accountId: $accountId, filename: $filename, base64: $base64) {
      urlImage
    }
  }
`

export const MUTATION_UPDATE_LANDING_PAGE = gql`
  mutation UpdateAccountLandingPageFranchise(
    $updateAccountLandingPageFranchiseId: ID!
    $identifier: String!
    $active: Boolean!
    $colorPrimary: LandingPageFranchiseColorPrimaryInput!
    $franchisees: [LandingPageFranchiseeInput]!
    $favicon: String
    $logo: String
    $banner: String
  ) {
    updateAccountLandingPageFranchise(
      id: $updateAccountLandingPageFranchiseId
      identifier: $identifier
      active: $active
      colorPrimary: $colorPrimary
      franchisees: $franchisees
      favicon: $favicon
      logo: $logo
      banner: $banner
    )
  }
`

export const QUERY_GET_DATA_CONFIG_LANDING_PAGE = gql`
  query LandingPageFranchise($franchiseId: String!) {
    landingPageFranchise(franchiseId: $franchiseId) {
      active
      banner
      colorPrimary {
        primary
        primaryLight
        textColor
      }
      db_name
      favicon
      franchisees {
        active
        email
        id
        logo
        name
        order
        phone
        url
        whatsapp
      }
      id
      identifier
      logo
      name
    }
  }
`
