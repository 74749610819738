<template>
  <div>
    <loading-dialog :loading="$apollo.loading">
      <v-form ref="form" v-model="valid" class="auth-form" lazy-validation>
        <v-row>
          <v-col cols="12">
            <CardInfoLandingPage v-model="dataLandingPage" :data-landing-page="dataLandingPage"></CardInfoLandingPage>
          </v-col>
          <v-col cols="12">
            <CardImageLandingPage v-model="dataLandingPage" :data-landing-page="dataLandingPage" :dbName="dbName"></CardImageLandingPage>
          </v-col>
          <v-col cols="12">
            <CardAccounts v-model="dataLandingPage" :data-landing-page="dataLandingPage"></CardAccounts>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="6">
                <v-row>
                  <v-col cols="auto">
                    <mf-button label="Cancelar" text color="error" @click="exit"></mf-button>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row justify="end">
                  <v-col cols="auto">
                    <mf-button outlined label="Ver Preview" @click="dialogPreview = true"></mf-button>
                  </v-col>
                  <v-col cols="auto">
                    <mf-button label="Salvar" :disabled="disableButton" @click="save"></mf-button>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
      <preview-landing-page-dialog
        v-if="dialogPreview"
        v-model="dialogPreview"
        :data-account="DataPreviewLandingPage"
        :color="DataPreviewLandingPage.colorPrimary"
      ></preview-landing-page-dialog>
    </loading-dialog>
  </div>
</template>
<script>
import { QUERY_GET_DATA_CONFIG_LANDING_PAGE, MUTATION_UPDATE_LANDING_PAGE } from '../../graphql/landing-page'
export default {
  name: 'ConfigLandingPage',
  components: {
    CardInfoLandingPage: () => import('../../components/card/CardInfoLandingPage.vue'),
    CardImageLandingPage: () => import('../../components/card/CardImageLandingPage.vue'),
    PreviewLandingPageDialog: () => import('../../components/dialogs/PreviewLandingPageDialog.vue'),
    CardAccounts: () => import('../../components/card/CardAccounts.vue'),
    LoadingDialog: () => import('@/components/base/LoadingDialog.vue')
  },
  data: () => ({
    dataLandingPage: {
      active: false,
      identifier: '',
      banner: '',
      logo: '',
      favicon: '',
      colorPrimary: {
        primary: '#FFFFFFFF',
        primaryLight: '#FFFFFFFF',
        textColor: '#FFFFFFFF'
      },
      franchisees: []
    },
    valid: false,
    dialogPreview: false,
    dbName: '',
    disableButton: false
  }),
  computed: {
    DataPreviewLandingPage() {
      return {
        banner: this.dataLandingPage.banner,
        logo: this.dataLandingPage.logo,
        colorPrimary: {
          ...this.dataLandingPage.colorPrimary
        }
      }
    },
    franchiseId() {
      return this.$route.params.id
    }
  },
  apollo: {
    landingPage: {
      query: QUERY_GET_DATA_CONFIG_LANDING_PAGE,
      fetchPolicy: 'network-only',
      variables() {
        return {
          franchiseId: this.franchiseId
        }
      },
      update({ landingPageFranchise }) {
        this.dataLandingPage = {
          id: landingPageFranchise?.id || '',
          banner: landingPageFranchise?.banner || this.dataLandingPage.banner,
          colorPrimary: landingPageFranchise?.colorPrimary || this.dataLandingPage.colorPrimary,
          identifier: landingPageFranchise?.identifier || this.dataLandingPage.identifier,
          favicon: landingPageFranchise?.favicon || this.dataLandingPage.favicon,
          logo: landingPageFranchise?.logo || this.dataLandingPage.logo,
          franchisees: landingPageFranchise?.franchisees || [],
          active: landingPageFranchise?.active || this.dataLandingPage.active
        }
        this.dbName = landingPageFranchise?.db_name || ''
      },
      error(error) {
        this.disableButton = true
        this.$alert({
          alert_message: 'Erro ao buscar os dados da rede',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
        console.error('Ocorreu um erro:', error)
      }
    }
  },
  methods: {
    async save() {
      if (this.validateAccounts()) {
        return this.$alert({
          alert_message: 'Precisa de pelo menos 1 filiado ativo',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
      if (!this.$refs.form.validate()) {
        return this.$alert({
          alert_message: 'Falha ao validar campos',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
      const colorPrimary = this.dataLandingPage.colorPrimary
      delete colorPrimary.__typename
      try {
        await this.$apollo
          .mutate({
            mutation: MUTATION_UPDATE_LANDING_PAGE,
            variables: {
              updateAccountLandingPageFranchiseId: this.dataLandingPage.id,
              identifier: this.dataLandingPage.identifier,
              active: this.dataLandingPage.active,
              colorPrimary: colorPrimary,
              franchisees: this.dataLandingPage.franchisees.map(item => {
                return {
                  active: item.active,
                  id: item.id,
                  order: item.order
                }
              }),
              favicon: this.dataLandingPage.favicon,
              logo: this.dataLandingPage.logo,
              banner: this.dataLandingPage.banner
            }
          })
          .then(({ data }) => {
            this.$alert({
              alert_message: `Alteração realizada com sucesso`,
              alert_title: 'Sucesso!',
              alert_color: 'success',
              alert_icon: 'mdi-check-circle'
            })
            setTimeout(() => {
              this.$router.go()
            }, 300)
          })
      } catch (error) {
        this.$alert({
          alert_message: 'Erro ao salvar.',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    },
    validateAccounts() {
      const valid = this.dataLandingPage.franchisees.filter(i => i?.active)

      return valid.length === 0
    },
    exit() {
      this.$router.push('/franchises')
    }
  }
}
</script>

<style>
.label {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
.title {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
</style>
